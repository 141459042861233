#rank {
    position: relative;
    height: var(--image-height);
    width: calc(100% - var(--image-width) - var(--padding));
    margin-right: var(--padding);
    --rank-columns: 3;
}

.rank-item {
    position: relative;
    display: block;
    width: 100%;
    height: var(--image-height);
    white-space: nowrap;
    overflow: hidden;
    padding-right: var(--padding);
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.rank-item img {
    color: transparent;
}

.rank-item__rank {
    position: absolute;
    color: #fff;
    opacity: 0.9;
    font-size: 6rem;
    font-weight: bold;
    padding: inherit;
    bottom: 0;
    right: 0;
    line-height: 0.8em !important;
}

.rank-item__background {
    overflow: hidden;
}

.rank-item__background img {
    filter: blur(10px) brightness(50%) contrast(80%)
}

.p-skeleton.rank-item__background {
    background-color: #000;
    height: 100% !important;
    width: 90% !important;
}

.rank-item__movie, .rank-item__background {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-flex;
    height: 100%;
    width: 90%;
    white-space: normal;
    border-radius: var(--radius);
}

.rank-item__poster {
    position: relative;
    width: var(--image-width);
    height: var(--image-height);
    border-radius: var(--radius);
    pointer-events: none;
    flex-shrink: 0;
}

.rank-item__poster img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--radius);
}

.rank-item__detail {
    width: 100%;
    padding: var(--padding);
    color: #fff;
    overflow: hidden;
}

.rank-item__detail > * {
    display: block;
}

.rank-item__detail span:not(:last-child) {
    margin-bottom: 0.3rem;
}

#rank__prev, #rank__next {
    display: inline-flex;
    align-items: center;
    position: absolute;
    top: 0;
    height: 100%;
    padding: var(--padding-sm);
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
}

#rank__prev button, #rank__next button {
    transform: scale(0);
    transition: all 0.2s ease-in-out;
    color: black;
    background: white;
    border-color: white;
    box-shadow: none;
    pointer-events: all;
}

#rank:hover #rank__prev button, #rank:hover #rank__next button {
    transform: scale(1);
}

#rank:hover #rank__prev, #rank:hover #rank__next {
    opacity: 0.8;
}

#rank__prev {
    left: 0;
}

#rank__next {
    right: 0;
}

.rank-item__detail > h3 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

@supports (-webkit-line-clamp: 2) {
    .rank-item__detail > h3 {
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}


/* Smartphone */
@media only screen and (max-width: 768px) {
    #rank {
        height: 100%;
        width: unset;
        flex-grow: 1;
        margin-right: 0;
        min-width: 0;
        --rank-columns: 3;
    }

    .rank-item {
        padding-right: var(--padding-sm);
    }

    .rank-item__background:not(.p-skeleton) {
        display: none;
    }

    .rank-item__rank {
        font-size: 3.5rem;
        right: 0;
        padding: 0;
    }

    .rank-item__movie {
        position: static;
        width: 100%;
    }

    .rank-item__detail {
        position: absolute;
        width: var(--image-width);
        height: 100%;
        padding: var(--padding-sm);
    }

    .rank-item__detail > h3 {
        position: relative;
        font-size: 1em;
        margin: 0;
        line-height: 1.2em;
        max-height: 3.6em;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    @supports (-webkit-line-clamp: 2) {
        .rank-item__detail > h3 {
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }

    .rank-item__detail *:not(h3) {
        display: none;
    }
}

@media only screen and (min-width: 767.97px) and (max-width: 1200px) {
    .rank-item__detail > h3 {
        margin: 0 0 1rem 0;
    }
}