#popup {
    display: flex;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--padding-sm);
    z-index: var(--overlay-index);
    background: rgba(0, 0, 0, 0.7);
}

#popup, #popup * {
    transition: all 0.2s ease-in-out;
}

.popup-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--padding);
    max-width: 50vw;
    max-height: 80vh;
}

#popup .popup-container img {
    max-width: 50vw;
    max-height: calc(90vh - 280px);
}

#popup .popup-container .p-button {
    width: max-content;
    flex: 0 0 auto;
    color: #000;
    background-color: white;
    border-color: white;
    box-shadow: none;
    opacity: 0.9;
    padding: 0.5em;
}

#popup .popup-container .p-button, #popup .popup-container .p-button-icon {
    font-size: 0.7rem;
}

#container-2406e5a5c1584a93d53e494dd2148236 {
    margin-top: 3rem;
}

div[id^="M800087ScriptRootC1305486"] {
    margin-top: 3rem;
    width: max-content;
    max-width: 100%;
}


@media only screen and (max-width: 768px) {
    #popup {
        flex-direction: column;
    }

    #popup .popup-container, #popup .popup-container img {
        width: 100%;
        max-width: 100vw;
        max-height: 80vh;
    }

    div[id='yuthanhthien.top_22966_320x50_624e1836dc2aa'] {
        margin-top: 3rem;
    }

    .popup-container > div[id='yuthanhthien.top_22966_320x50_624e1836dc2aa'] {
        margin-top: 0;
    }

    div[id^="M800087ScriptRootC1305486"] {
        margin-top: 1rem;
    }
}