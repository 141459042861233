#maintenance {
    text-align: center;
    padding: 5rem 0;
}

#maintenance img {
    width: 20%
}

#maintenance h1 {
    font-size: 3em;
    margin-bottom: 0;
}

#maintenance h2 {
    font-size: 2em;
}

@media only screen and (max-width: 768px) {
    #maintenance img {
        width: 50%
    }
    
    #maintenance h1 {
        font-size: 2em;
        margin-bottom: 0;
    }
    
    #maintenance h2 {
        font-size: 1.2em;
    }
}