#booksingle {
    min-height: var(--min-page-height);
}

#booksingle .content.booksingle-content {
    width: 60%;
    z-index: 2;
}

#chapter-content, .chapter-content__toolbar {
    padding: var(--padding);
    border: 1px solid #414141;
    border-radius: var(--radius-lg);
    background: #202124;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
}

#chapter-content .ql-editor {
    padding: 0;
    cursor: none !important;
    user-select: none !important;
    pointer-events: none !important;
}

#chapter-content > *:not(:last-child) {
    margin-bottom: var(--padding);
}

.chapter-content__toolbar {
    display: flex;
    position: sticky;
    flex-wrap: wrap;
    top: -0.1px;
    margin-bottom: var(--padding-sm);
    justify-content: space-between;
    align-items: center;
    gap: var(--padding-xl);
    z-index: 3;
}

.chapter-content__toolbar.top {
    background: var(--accent-color);
    border-radius: 0 0 var(--radius) var(--radius);
}

.chapter-content__toolbar .booksingle-chapters {
    display: none;
    width: 100%;
    flex: 0 0 auto;
    border-radius: var(--radius-sm);
    padding: var(--padding-md)
}

.chapter-content__toolbar .booksingle-chapters .booksingle-chapters__header {
    font-size: 1.1em;
}

.chapter-content__resizer > span {
    font-size: 1.2em
}

.chapter-content__resizer {
    display: flex;
    width: max-content;
    flex: 0 0 auto;
    justify-content: space-between;
    align-items: center;
    gap: var(--padding-md)
}

.chapter-content__resizer .p-button, .chapter-content__paginator .p-button {
    border-radius: 50%;
    border-color: var(--accent-color);
    background-color: var(--accent-color);
}

.chapter-content__resizer .p-button:hover, .chapter-content__paginator .p-button:hover {
    background-color: #555555;
    border-color: #555555;
}

.chapter-content__resizer .p-button:focus, .chapter-content__paginator .p-button:focus {
    box-shadow: none;
}

.chapter-content__toolbar.top .chapter-content__resizer .p-button,
.chapter-content__toolbar.top .chapter-content__paginator .p-button
{
    background-color: #202124;
}

.chapter-content__paginator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--padding-md);
}

.chapter-content__paginator .p-button {
    border-radius: var(--radius-sm)
}

.chapter-content__title {
    flex: 1 1 auto;
    margin: 0;
    font-size: 1.4em;
}

.chapter-content__image {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 35%;
}

.chapter-content__image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.chapter-content__music {
    width: 100%;
}

/* BOOK DETAILS */
#booksingle .sidebar.booksingle-detail {
    position: sticky;
    top: var(--padding);
    height: max-content;
}

.booksingle-detail__wrapper {
    width: 100%;
    height: 100vh;
    overflow: hidden scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.booksingle-detail__wrapper::-webkit-scrollbar {
    display: none;
}

.booksingle-detail__wrapper > *:not(:last-child) {
    margin-bottom: var(--padding);
}

.booksingle-detail__wrapper > span {
    display: block;
    font-size: 1.2em;
}

.booksingle-detail__wrapper > span.booksingle__note {
    font-size: 1em;
}

.booksingle-detail__wrapper > span:not(:last-child) {
    margin-bottom: var(--padding-md);
}

.booksingle-detail__wrapper > span.booksingle__views {
    margin-bottom: var(--padding-xl)
}

.booksingle-detail__background {
    position: absolute;
    width: 100%;
    height: 100%;
    color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(2rem) brightness(60%) contrast(90%);
    z-index: -1;
}

.booksingle-detail .booksingle__title {
    margin-top: 0;
}

.booksingle-detail .booksingle__rating > * {
    display: inline-block;
}

.booksingle-detail .booksingle__rating .p-rating .p-rating-icon:focus {
    box-shadow: none;
}

.booksingle-detail .booksingle__rating .p-rating-icon.pi.pi-star, .booksingle__rating .p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
    color: var(--accent-color);
}

.booksingle-detail .booksingle__rating .p-rating-icon.pi.pi-star-o {
    color: #fff;
}

.booksingle-detail .booksingle__note {
    margin-top: var(--padding-md);
    padding: var(--padding-md);
    font-weight: bold;
    border-radius: var(--radius-lg);
    background: var(--accent-color)
}



/* BOOK EXTENSION */
.booksingle-extension__content {
    background: linear-gradient(#323232 0%, #131313 100%);
    border: 1px solid #414141;
    padding: var(--padding);
    border-radius: 0 0 var(--radius-lg) var(--radius-lg);
}

.booksingle-extension #facebook, .booksingle-extension #facebook-page {
    border-radius: var(--radius-sm);
}

.booksingle-extension .booksingle__comment {
    width: 100%;
    border-radius: var(--radius-sm);
    overflow: hidden;
}

.booksingle-extension .booksingle__comment * {
    width: inherit !important;
    height: max-content;
    background: white;
    transition: all 0.5s ease-in-out;
}


.booksingle-extension .p-tabmenu {
    border-radius: var(--radius-lg) var(--radius-lg) 0 0;
    overflow: hidden;
}

.booksingle-extension .p-tabmenu .p-tabmenu-nav {
    display: flex;
    border: none;
}

.booksingle-extension .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    flex: 1 1 auto;
}

.booksingle-extension .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    background: linear-gradient(#323232 0%, #131313 100%);
    color: #fff;
    border: 1px solid #414141;
    border-radius: 0;
}

.booksingle-extension .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: var(--accent-color);
    border: 1px solid var(--accent-color);
}

.booksingle-extension .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
    box-shadow: none;
}

/* EPISODES LIST */

.booksingle-chapters {
    padding: var(--padding);
    background: linear-gradient(#323232 0%, #131313 100%);
    border: 1px solid #414141;
    border-radius: var(--radius-lg);
}

.booksingle-chapters__header {
    margin: 0 0 var(--padding) 0;
}

.booksingle-chapters__container {
    display: flex;
    flex-direction: column;
    gap: var(--padding-sm);
}


.booksingle-chapters__container > hr {
    width: 100%;
    border: 0.5px solid #fff;
}

.booksingle-chapters__list {
    display: flex;
    width: 100%;
    flex: 0 0 auto;
    flex-wrap: wrap;
    gap: var(--padding-sm);
}

.booksingle-chap {
    display: inline-block;
    padding: var(--padding-xs) var(--padding-sm);
    text-align: center;
    border-radius: var(--radius-sm);
    background: #555555;
    cursor: pointer;
}

.booksingle-chap:hover {
    box-shadow: inset 0 0 0 1px var(--accent-color);
}

.booksingle-chap.active {
    background: var(--accent-color);
}




/* RANK */

#booksingle #rank {
    width: 100%;
    margin-right: 0;
    --image-height: 8rem;
    --image-width: 100%;
}

#booksingle .rank-item__background {
    display: none;
}

#booksingle .rank-item__detail {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: var(--padding-md);
    border-radius: var(--radius);
    background: rgba(0, 0, 0, 0.5)
}

#booksingle .rank-item__detail > h3 {
    position: relative;
    font-size: 1em;
    margin: 0;
    margin-bottom: var(--padding-md);
    line-height: initial;
    max-height: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

@supports (-webkit-line-clamp: 2) {
    #booksingle .rank-item__detail > h3 {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: none;
    }
}

#booksingle .rank-item__detail > span {
    font-size: 0.8em;
}


#booksingle .rank-item__rank {
    font-size: 5rem;
    right: 0;
    padding: 0;
}


@media only screen and (max-width: 768px) {
    #booksingle .content.booksingle-content, #booksingle .sidebar.booksingle-detail {
        width: 100%;
    }

    #booksingle.container {
        flex-direction: column-reverse;
    }

    #booksingle .sidebar.booksingle-detail {
        position: relative;
        top: unset;
        height: max-content;
    }

    .chapter-content__title {
        width: 100%;
        flex: 0 0 auto;
    }

    .chapter-content__toolbar {
        gap: var(--padding-md)
    }

    .chapter-content__toolbar.top .booksingle-chapters {
        display: block;
    }

    .chapter-content__toolbar .booksingle-chapters__container {
        overflow: hidden
    }

    .chapter-content__toolbar .booksingle-chapters__list {
        flex-wrap: nowrap;
        overflow-x: auto;
    }

    .chapter-content__toolbar .booksingle-chap {
        white-space: nowrap;
    }

    .chapter-content__toolbar .booksingle-chapters__header {
        display: none;
    }

    .chapter-content__resizer {
        gap: var(--padding-sm);
        justify-content: space-between;
    }

    .chapter-content__resizer span {
        font-size: 1em;
    }

    .chapter-content__resizer .p-button {
        width: 1.5rem;
        height: 1.5rem;
    }

    .chapter-content__paginator .p-button {
        height: 1.5rem;
        padding: 0 var(--padding-sm);
    }
}

@media only screen and (max-width: 1200px) and (orientation: portrait) {
    #booksingle .content.booksingle-content, #booksingle .sidebar.booksingle-detail {
        width: 100%;
    }

    #booksingle.container {
        flex-direction: column-reverse;
    }

    #booksingle .sidebar.booksingle-detail {
        position: relative;
        top: unset;
        height: max-content;
    }

    .booksingle-detail__wrapper {
        height: max-content;
    }

    .chapter-content__toolbar.top .booksingle-chapters {
        display: block;
    }

    .chapter-content__toolbar .booksingle-chapters__header {
        display: none;
    }

    .chapter-content__toolbar .booksingle-chapters__container {
        overflow: hidden
    }

    .chapter-content__toolbar .booksingle-chapters__list {
        flex-wrap: nowrap;
        overflow-x: auto;
    }

    .chapter-content__toolbar .booksingle-chap {
        white-space: nowrap;
    }
}

@media only screen and (max-width: 1200px) and (orientation: landscape) {
    #booksingle .content.booksingle-content {
        width: 55%
    }

    .chapter-content__toolbar {
        justify-content: space-around;
    }
}