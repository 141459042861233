#qc {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    gap: var(--padding);
    justify-content: center;
    transition: all 0.5s ease-in-out;
    margin: var(--padding-lg) 0;
    --ad-height: 1.5rem;
    --banner-height: 7rem;
    --banner-width: calc(var(--banner-height) * 22/5.5);
    --ad-padding-sm: 0.2rem;
}

main > #qc:first-child {
    margin-top: 0;
}

div[id="yuthanhthien.top_22966_728x90_624dfc5d56d48"] {
    background: #272727;
}

.qc__aa {
    --ad-height: 25px;
}

.qc__aa {
    display: flex;
    width: 100%;
    position: relative;
    height: var(--ad-height);
    overflow: hidden !important;
}

.qc__aa__banner {
    display: block;
    border: 0px;
    padding: 0 !important;
    width: calc(100% / 3);
    height: 100%;
    overflow: hidden;
    flex: 1 1 auto;
}

.qc__cus__banner {
    display: inline-flex;
    position: relative;
    height: var(--banner-height);
    overflow: hidden !important;
}

.qc__cus__banner {
    width: var(--banner-width);
    flex-shrink: 0;
}

.qc__cus__banner img {
    position: absolute;
    width: 100%;
    height: 100%;
}

div[id="yuthanhthien.top_22966_728x90_624dfc5d56d48"],
div[id^="M851276ScriptRootC1402401"],
div[id^="M851276ScriptRootC1402404"] {
    height: max-content;
    max-height: var(--banner-height);
    width: max-content;
    max-width: calc(728px * 0.5);
}

div[id="yuthanhthien.top_22966_728x90_624dfc5d56d48"] {
    max-width: 100%;
}

div[id^="M851276ScriptRootC1402401"] {
    max-width: calc(728px * 0.8);
}

div[id^="M851276ScriptRootC1402404"] {
    width: 1px;
    flex-grow: 1;
}

@media only screen and (max-width: 768px) {
    #qc {
        margin: var(--padding) 0;
        gap: 0;

        --banner-width: calc((100vw - 2rem) * 0.75);
        --banner-height: calc(var(--banner-width) * 5.5/22);
    }

    #qc.no-margin {
        margin: 0 0 var(--padding); 
    }

    div[id^="M851276ScriptRootC1402401"] {
        max-width: 70%
    }
}